@charset "UTF-8";
@import "~@/assets/sass/_function";

.Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba($page-gray-bg, 0.3);
}
