.FooterPage-ajfbajs2313 {
  width: 100vw;
  padding: 0 20px;
  font-size: 12px;
  color: #124089;
  .account {
    img {
      width: 96px;
      height: 96px;
    }
  }
  .info {
    ul {
      li {
        margin-bottom: 8px;
        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          color: #124089;
          margin-bottom: 2px;
        }
        .link-item {
          margin-right: 10px;
          font-size: 12px;
          color: #124089;
        }
      }
    }
    .contact {
      margin-bottom: 8px;
      .item {
        display: flex;
        .icon {
          width: 9px;
          height: 12px;
          margin-right: 6px;
        }
      }
    }
    .official {
      font-weight: normal;
      font-size: 12px;
      margin-left: 15px;
    }
    .information {
      h3 {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: #124089;
        margin-bottom: 2px;
      }
    }
    .line {
      position: relative;
      left: -20px;
      width: 100vw;
      height: 1px;
      margin: 10px 0;
      background: #124089;
    }
  }
  .long-li {
  }
  .CallusLayer {
    transform: translate(-50%, -50%) scale(0.48);
  }
}
