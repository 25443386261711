@import "@/styles/common";

.home-footer {
  width: 100%;
  // background: url("https://oss.5rs.me/oss/uploadfe/png/a9294be83a3455fe33461e8b1d088f23.png") no-repeat center;
  .modal {
    z-index: 9999;
  }
  &.enterAnimation {
    background: url("https://oss.5rs.me/oss/uploadfe/png/a9294be83a3455fe33461e8b1d088f23.png") no-repeat center;
    background-size: cover;
    .home-footer-container {
      .info {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &.notHome {
    background-image: url("https://oss.5rs.me/oss/uploadfe/png/327ba0f1e5f8a4fb1edb398af6f543a2.png");
    background-size: 100% 1.33rem;
    background-position: left bottom;
    background-repeat: no-repeat;
    .home-footer-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      padding-top: 0.7rem;
      padding-bottom: 2rem;
    }
  }
  &-container {
    width: 10.8rem;
    height: 100vh;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 3.26rem;
    position: relative;
    .info {
      width: 8.71rem;
      opacity: 0;
      transition: all 1s;
      transform: translateY(1rem);
      margin: 0 auto;
      ul {
        display: flex;
        width: 8.71rem;
        justify-content: space-between;
        margin-bottom: 0.39rem;
        padding: 0 0.3rem;
        box-sizing: border-box;
        > li:nth-child(6) {
          margin-right: 0;
        }
        > li {
          > p {
            height: 0.22rem;
            font-size: 0.16rem;
            color: #124089;
            line-height: 0.22rem;
            letter-spacing: 0.01rem;
            margin-bottom: 0.12rem;
            font-family: SourceHanSansCN-Medium;
          }
          > a {
            display: block;
            font-size: 0.12rem;
            color: #124089;
            line-height: 0.2rem;
            letter-spacing: 0.01rem;
            margin-bottom: 0.03rem;
            font-family: SourceHanSerifCN;
          }
          > span {
            display: block;
            font-size: 0.12rem;
            color: #124089;
            line-height: 0.2rem;
            letter-spacing: 0.01rem;
            cursor: pointer;
            margin-bottom: 0.03rem;
            font-family: SourceHanSerifCN;
          }
        }
        .long-li {
          margin-left: 0.4rem;
          text-align: center;
          > p {
            text-align: center;
            font-family: SourceHanSansCN-Medium;
          }
          .fr {
            object-fit: cover;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          .account {
            width: 0.96rem;
            height: 0.96rem;
          }
          .official {
            font-size: 0.15rem;
            color: #124089;
            line-height: 0.2rem;
            letter-spacing: 0.01rem;
            margin-top: 0.1rem;
            font-family: SourceHanSerifCN;
          }
        }
      }
      .br {
        width: 8.71rem;
        height: 0.02rem;
        background: url("https://oss.5rs.me/oss/uploadfe/png/8036ef5bfdcfa9a779413990fe8f0e82.png") top left/100% 100%
          no-repeat;
      }
      .company-info {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 0.39rem;
        box-sizing: border-box;
        .logo {
          display: block;
          width: 1.39rem;
          height: 0.53rem;
        }
        .contact {
          > div:last-child {
            margin-bottom: 0;
          }
          .item {
            display: flex;
            align-items: center;
            height: 0.17rem;
            font-size: 0.12rem;
            font-weight: 400;
            color: #124089;
            line-height: 0.17rem;
            margin-bottom: 0.15rem;
            .icon {
              width: 0.09rem;
              height: 0.13rem;
              display: flex;
              align-items: center;
              justify-content: center;
              > img {
                width: 100%;
                height: 100%;
              }
            }
            .content {
              display: flex;
              align-items: center;
              margin-left: 0.06rem;
              .address {
                font-family: SourceHanSerifCN;
                letter-spacing: 0.005rem;
              }
            }
          }
          .index-item {
            margin-bottom: 0.05rem;
          }
          a {
            font-size: 0.12rem;
            font-family: SourceHanSerifCN;
            letter-spacing: 0.005rem;
            font-weight: 400;
            color: #124089;
          }
        }
        .line {
          width: 0.01rem;
          height: 0.81rem;
          background: #124089;
        }
        .information {
          font-size: 0.12rem;
          height: 1.1rem;
          > h3 {
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #124089;
            line-height: 0.12rem;
            margin-bottom: 0.18rem;
          }
          > p {
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #124089;
            margin-bottom: 0.15rem;
            line-height: 0.17rem;
          }
        }
      }
    }
  }
}
.home-footer-m {
  .home-footer-container {
    height: 7.65rem;
  }
}
