@charset "UTF-8";
@import '@/styles/icon.scss';
// switch
//-----------------------------------------------------	
$borderBoxSwitch:                true !default; // true为*{box-sizing:border-box;}


// font相关
//-----------------------------------------------------	
$fontSize:                       14px !default;
$fontLineHeight:                 1.5 !default;
$fontFamily:                     "Microsoft Yahei", "miui", "consolas", sans-serif !default;


// 背景色，文本色，边框色，链接色
//-----------------------------------------------------	
$colorText:                      #333 !default;
$colorLink:                      #333 !default; // 如果只有一个值则滑过的值为这个值的darken 5%，如果有两个值则第二个值为滑过的值
$colorTextLight:                 #777 !default;
$colorBg:                        #fff !default;
$colorBgLight:                   #f7f7f7 !default;
$colorBorder:                    #ddd !default;
$colorPrimary:                   #31b392 !default;
$colorPrevious:                  #ffaa3d !default;

$colorWarning:                   #ff6305 !default;
$colorPlaceholder:               #999 !default; // input placeholder color
$colorDanger:                    #ff2e36 !default;
$colorDisabled:                  #777 #eee #eee !default; // textColor bgColor borderColor
$colorBgImg:                     #ecf3f8 !default;//图片未加载出来之前的占位背景色

$colorBase:                      #f8f8f8 !default;
$colorTitle:                     #e1e1e1 !default;

$colorTemplate:                  #31b392 !default;//风格模板主题色


// Colors
// -----------------------------------------------------------------------------
$black:                          #000 !default;
$gray3:                          #333 !default;
$gray5:                          #555 !default;
$gray6:                          #666 !default;
$gray7:                          #777 !default;
$gray9:                          #999 !default;
$grayA:                          #AAA !default;
$grayC:                          #ccc !default;
$grayD:                          #ddd !default;
$grayE:                          #eee !default;
$grayF:                          #f5f5f5 !default;
$white:                          #fff !default;

$orange:                         #feb794 !default;
$yellow:                         #f8b020 !default;
$red:                            #f00 !default;
$redDark:                        #ff4500 !default;
$green:                          #62c3a2 !default;
$greenLight:                     #77cdb0 !default;
$sliver:                         #7da0b4 !default;
$blue:                           #1a6fd8 !default;
$blueBtn:                        #3693ff !default;


// radius
//-----------------------------------------------------	
$radiusBase:                     5px !default;
$radiusSmall:                    3px !default;


// btn
// 默认的按钮大小，颜色，圆角
//-----------------------------------------------------	
$btnSize:                        1em 42px !default; // 默认按钮的左右padding，高度
$btnColor:                       $white $green !default; // 默认按钮的textColor, bgColor
$btnRadius:                      false !default;


// z-index
//-----------------------------------------------------	
$zIndexHeader:                   1000 !default;
$zIndexFooter:                   2000 !default;
$zIndexPopup:                    3000 !default;
$zIndexOverlay:                  4000 !default; // 默认高于header和footer部分


// margin
//-----------------------------------------------------	
$baseMargin:                     20px !default;
$basePadding:                    10px !default;


// header,footer等的高度
//-----------------------------------------------------	
$headerHeight:                   40px !default;
$footerHeight:                   50px !default;


// Popup
//-----------------------------------------------------
$popupMask:                      rgba(0,0,0,.5) !default;
$popupBg:                        #fff !default;
$popupText:                      #333 !default;


// Margin
//-----------------------------------------------------
$marginBase:                     10px !default;
$marginLarge:                    20px !default;
$marginSmall:                    5px !default;

//audio
$baseAudioBg:                    #e8f0ee !default;

//video
$videoTheme:                     #feab3c !default;

//pdf
$pdfTheme:                       #eb716f !default;

//image
$imageTheme:                     #309ced !default;

//listen
$listenTheme:                    #4cbdfc !default;

//answer
$answerTheme:                    #4ba7a9 !default;

//book
$bookTheme:                      #40bac4 !default;

//qa
$qaTheme:                        #ff729d !default;

//模版

$COMMON:                         #50acf0 !default;
$ACADEMIC:                       #01a6e8 !default;

.theme-title{
  font-size: 40px;
  line-height: 40px;
  color: #174f93;
  text-align: center;
  font-weight:600;
}

.theme-summary{
  color: #2D374A88;
  margin: 0 auto;
  margin-top:32px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 400;
}

.white-font{
  color:#FFF!important;
}

* {
  font-family:PingFangSC-Regular, sans-serif;
}
html{
  width:100vw;
  overflow-x: hidden;
}


.left {
  float: left !important;
}

.right {
  float: right !important;
}
.inline-block{
  display: inline-block;
}
.mb10{
  margin-bottom: 10px;
}
.mb20{
  margin-bottom: 20px;
}
.btn {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0 1em;
  white-space: nowrap;
}
.clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
.text-center{
  text-align: center;
}
.m1200{
  max-width: 1200px;
  margin: 0 auto;
}

@mixin trans($str) {
  -webkit-transform:$str;
  -moz-transform:$str;
  -ms-transform:$str;
  transform:$str;
}

.left-ico,.right-ico{
  width:20px;
  height:20px;
  display: inline-block;
}

//左箭头
.left-ico:before{
  content:"";
  display: inline-block;
  margin:auto;
  width:15px;
  height:15px;
  border-style:solid solid none none;
  border-width:2px;
  border-color:#333;
  cursor: pointer;
  @include trans((rotate(-135deg)));
}

//右箭头
.right-ico:before{
  content:"";
  display: inline-block;
  margin:auto;
  width:15px;
  height:15px;
  border-style:solid solid none none;
  border-width:2px;
  border-color:#333;
  cursor: pointer;
  @include trans((rotate(45deg)));

}


@mixin ellipsis-lines($lines: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  word-break: break-all;
}

@mixin  getAnimate($str){
  -webkit-animation:$str;
  -moz-animation:$str;
  -ms-animation:$str;
  animation:$str;
}

@mixin  setAnimate($name) {
  @-webkit-keyframes #{$name}{
    @content;
  }
  @-moz-keyframes #{$name}{
    @content;
  }
  @keyframes #{$name}{
    @content;
  }
}

@mixin transPoint ($str){
  -webkit-transform-origin:$str;
  -moz-transform-origin:$str;
  -ms-transform-origin:$str;
  transform-origin:$str;
}

@mixin transition($str) {
  -webkit-transition:$str;
  -moz-transition:$str;
  -ms-transition:$str;
  transition:$str;
}



//媒体查询
@mixin media-1024 {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin media-1280 {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin media-1366 {
  @media (max-width: 1366px) {
    @content;
  }
}

@mixin media-1440 {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin media-1600 {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin centerAbs() {
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  left:50%;
  top:50%;
  position: absolute;
  
}


@include media-1024(){

  // body,html{
  //   display: flex;
  //   flex:1;
  //   min-width: 1280px;
  //   margin:0 auto;
  //   #___gatsby{
  //     flex:0 0 1280px;
  //     width:1280px;
  //   }
  //   //@include trans(scale(.98));
  // }
  // .header{
  //   width:1280px;
  //   .header-box{
  //     width:1280px;
  //   }
  // }
  // .register-btn{
  //   @include trans(translateX(-80px))
  // }
  // .footer .footer-info ul li{
  //   width:145px;
  // }
  // .footer .company-info p label{
  //   margin-left:55px;
  // }
  // .news-category,.about-category{
  //   width:1280px!important;
  // }

  // * { touch-action: pan-y; }

}

.iconfont-2{
  font-family:"iconfont-2" !important;
  font-size:16px;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;}

@font-face {
  font-family: 'iconfont-2';  /* project id 2456951 */
  src: url('//at.alicdn.com/t/font_2456951_zggt5jff3br.eot');
  src: url('//at.alicdn.com/t/font_2456951_zggt5jff3br.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_2456951_zggt5jff3br.woff2') format('woff2'),
  url('//at.alicdn.com/t/font_2456951_zggt5jff3br.woff') format('woff'),
  url('//at.alicdn.com/t/font_2456951_zggt5jff3br.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_2456951_zggt5jff3br.svg#iconfont') format('svg');
}