.icons {
  background: no-repeat;
  display: inline-block;
  //background-size: ;
}

.icon-notice {
  width: 26px;
  height: 26px;
  background-size: 26px 26px;
  background-position: 0 0;
  background-image: url("https://oss.5rs.me/oss/uploadfe/png/f09b5eacfc1eceb6c35ec720983396f0.png");
}

.icon-call {
  width: 26px;
  height: 26px;
  background-size: 26px 26px;
  background-position: 0 0;
  background-image: url("https://oss.5rs.me/oss/uploadfe/png/d84b4b6c5f29e0d6a3d93565fd491f96.png");
}

.icon-qq {
  width: 26px;
  height: 26px;
  background-size: 26px 26px;
  background-position: 0 0;
  background-image: url("https://oss.5rs.me/oss/uploadfe/png/6010aeba2c54a28e34514b1e981f0d35.png");
}

.icon-wechat {
  width: 26px;
  height: 26px;
  background-size: 26px 26px;
  background-position: 0 0;
  background-image: url("https://oss.5rs.me/oss/uploadfe/png/2deb6d32dbd5777e4383d530f5d196e1.png");
}

.icon-q_wechat {
  width: 26px;
  height: 26px;
  background-size: 26px 26px;
  background-position: 0 0;
  background-image: url("https://oss.5rs.me/oss/uploadfe/png/c8d42a529cd2443d14618711caa5ff60.png");
}

.icon-top {
  width: 26px;
  height: 26px;
  background-size: 26px 26px;
  background-position: 0 0;
  background-image: url("https://oss.5rs.me/oss/uploadfe/png/3663171ad2a8fd64def9da33ec30d286.png");
}
