.NewHeader-shdhfalk1123 {
  flex-shrink: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 62px;
  .NewHeader-header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    background: #fff;
    img {
      &:nth-child(1) {
        width: 68px;
        height: 25px;
      }
      &:nth-child(2) {
        width: 15px;
        height: 17px;
      }
    }
  }
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: rgba($color: #000000, $alpha: .3);
  }
  .NewHeader-menu {
    display: flex;
    flex-direction: column;
    background: #fff;
    transform: translateY(-100%);
    li {
      display: flex;
      align-items: center;
      width: 100%;
      height: 52px;
      padding: 0 20px;
      font-weight: 600;
      color: #3D3D3D;
      font-size: 20px;
    }
  }
}