.footer-nav {
  bottom: 10vh !important;
}
.to-top {
  width: 0.18rem;
  height: 0.18rem;
  position: fixed;
  bottom: 0.16rem;
  cursor: pointer;
  right: calc((100vw - 10.8rem) / 2.5 + 0.04rem);
  z-index: 100;
  transition: all 0.8s linear;
  &.top-move {
    right: calc((100vw - 10.8rem) / 4 + 0.04rem);
    opacity: 0;
  }
  > img {
    width: 100%;
    height: 100%;
  }
}
.navmenu {
  position: fixed;
  right: calc((100vw - 10.8rem) / 2.5);
  bottom: 6vh !important;
  cursor: pointer;
  z-index: 99;
  transition: all 0.8s linear;
  &.move {
    right: calc((100vw - 10.8rem) / 4);
    opacity: 0;
  }
  .active {
    height: 2.95rem !important;
  }
  .h0 {
    padding-top: 0 !important;
  }
  .open-close-btn {
    width: 0.28rem;
    height: 0.28rem;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .dropdown-shallow {
    background: linear-gradient(180deg, rgba(11, 104, 179, 0.63) 0%, rgba(14, 84, 196, 0.65) 100%);
    &::after {
      content: "";
      position: absolute;
      /* 设置层级为-1，避免遮挡内容 */
      z-index: -1;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      top: -0.01rem;
      left: -0.01rem;
      background: linear-gradient(137deg, rgba(226, 241, 255, 1), rgba(135, 204, 255, 1)) 1 1;
    }
  }
  .dropdown-deep {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.67) 0%,
      rgba(255, 255, 255, 0.77) 100%,
      rgba(255, 255, 255, 0.78) 100%
    );
    &::after {
      content: "";
      position: absolute;
      /* 设置层级为-1，避免遮挡内容 */
      z-index: -1;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      top: -0.01rem;
      left: -0.01rem;
      background: linear-gradient(137deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)) 1 1;
    }
  }
  .dropdown {
    position: absolute;
    bottom: -0.06rem;
    right: -0.11rem;
    height: 0rem;
    padding-top: 0.09rem;
    border-radius: 0.15rem;
    overflow: hidden;
    transition: all 0.3s linear;
    > a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.08rem;
      .other {
        color: #fff;
      }
      span {
        width: 0.6rem;
        text-align: right;
        font-size: 0.14rem;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        color: #124089;
        margin-right: 0.08rem;
        margin-left: 0.14rem;
        // text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.5);
      }
      .icon {
        width: 0.28rem;
        height: 0.28rem;
        border-radius: 50%;
        // box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
        margin-right: 0.11rem;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .blue-box {
    background: #fff !important;
  }
  .white-box {
    background: linear-gradient(270deg, #0a78bb 0%, #259ed6 100%) !important;
  }
  .animation {
    width: 0.28rem;
    height: 0.28rem;
    border-radius: 50%;
    background: linear-gradient(270deg, #0a78bb 0%, #259ed6 100%);
    position: relative;
    transition: all 0.3s linear;
    .blue-item {
      background: #124089 !important;
    }
    .white-item {
      background: #fff !important;
    }
    .cloumn {
      width: 0.14rem;
      height: 0.02rem;
      border-radius: 0.01rem;
      background: #fff;
      position: absolute;
      left: 0.07rem;
      transition: all 0.3s linear;
    }
    .cloumn1 {
      top: 0.09rem;
    }
    .cloumn2 {
      top: 0.13rem;
    }
    .cloumn3 {
      top: 0.17rem;
    }

    // 动画
    .one-to-two {
      top: 0.13rem;
      transition: all 0.1s linear;
      transform: rotateZ(45deg);
    }
    .three-to-two {
      top: 0.13rem;
      transition: all 0.1s linear;
      transform: rotateZ(45deg);
    }
    .two-rotate {
      transition: all 0.2s linear;
      transform: rotateZ(135deg);
    }
  }
}
.navmenu-m {
  position: fixed;
  right: 0.6rem;
  bottom: 2.5rem !important;
  .navmenu-menu-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    outline: none;
    border: none;
    background-color: transparent;
  }
  .active {
    height: 4.82rem !important;
  }
  .dropdown {
    bottom: 0.5rem;
    right: 0;
    height: 0rem;
    > a {
      margin-bottom: 0.3rem;
      span {
        width: 1.2rem;
        font-size: 0.16rem;
        margin-right: 0.12rem;
      }
      .icon {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
  .animation {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    .cloumn {
      width: 0.26rem;
      height: 0.05rem;
      border-radius: 0.05rem;
      left: 0.12rem;
    }
    .cloumn1 {
      top: 0.12rem;
    }
    .cloumn2 {
      top: 0.225rem;
    }
    .cloumn3 {
      top: 0.33rem;
    }
    // 动画
    .one-to-two {
      top: 0.225rem;
    }
    .three-to-two {
      top: 0.225rem;
    }
  }
}
.to-top-m {
  bottom: 1.5rem;
  right: 0.6rem;
  width: 0.5rem;
  height: 0.5rem;
}
